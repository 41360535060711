import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './MessageComposer.module.scss';
import Text from '../controls/Text/Text';
import Message from '../../models/Message';
import { typing, send } from '../../api/Connect/Connect';
import { setApiError, refreshGenesysSession } from '../../store/actions/genesys';
import { setShowFeedback } from '../../store/actions/general';
import { addMessage, setQuickResponses, setIsTyping } from '../../store/actions/messages';
// import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const MessageComposer = () => {
  const components = window.sutherland_variables.components;
  const { t } = useTranslation();
  const { chatId, alias, secureKey, userId, nextPosition, chatEnded } = useSelector(state => state.genesys);
  // const { muiFontLoaded } = useSelector(state => state.general);
  const { mode } = useSelector(state => state.messages);
  const [newMessage, setNewMessage] = useState('');
  const debouncedInputValue = useDebounce(newMessage, 500);
  const [isTypingState, setisTypingState] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [composerRef, setComposerRef] = useState(null);
  // eslint-disable-next-line 
  const [fileuploadEnabled, setFileuploadEnabled] = useState(components["fileupload"]["enabled"])
  const dispatch = useDispatch()

  const sendMessage = (textareaRef = null) => {
    if (newMessage.trim() !== '') {

      if (newMessage === 'show_feedback') {
        dispatch(setShowFeedback(true));
      } else {
        if(textareaRef && textareaRef.current) { 
          textareaRef.current.style.height = 'inherit';
          setLimitReached(false);
        } else {
          composerRef.current.style.height = 'inherit';
          setLimitReached(false);
        }
        const message = {
          "type": "text",
          "text": newMessage,
          "incoming": false,
        }
        dispatch(setApiError(false));
        dispatch(addMessage(new Message(message)));
        dispatch(setQuickResponses([]));
        dispatch(setIsTyping(true))
        send({
          chatId,
          alias, 
          secureKey, 
          userId, 
          nextPosition,
          message: newMessage
        })
          .then((data) => {
            setNewMessage('');
            dispatch(refreshGenesysSession(data));
          })
          .catch((error) => {
            console.log('Genesys Error', error)
            dispatch(setApiError(true));
          })  
      }
    }
  };

  /*const showUploadModal = () => {
    dispatch(setShowUpload(true))
  }*/

  const handleTypingStart = (message, textareaRef = null) => {
    setNewMessage(message)
    if(textareaRef) { 
      textareaRef.current.style.height = 'inherit';
      if(message.length === 250) {
        setLimitReached(true);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;
      } else if (message.length === 0) {
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        setLimitReached(false);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      setComposerRef(textareaRef);
    }
    if (!isTypingState) {
      setisTypingState(true);
      typing({
        chatId,
        alias, 
        secureKey, 
        userId, 
        nextPosition,
        message: newMessage,
        isStartTyping: true
      });
    }
  };

  const stopTyping = useCallback(() => {
    typing({
      chatId,
      alias, 
      secureKey, 
      userId, 
      nextPosition,
      message: debouncedInputValue,
      isStartTyping: false
    })
      .then(() => {
        setisTypingState(false);
      });
  }, [chatId, alias, secureKey, userId, nextPosition, debouncedInputValue]);

  useEffect(() => {
    if (newMessage === debouncedInputValue && isTypingState && newMessage !== '') {
      stopTyping()
    }
  }, [debouncedInputValue, newMessage, isTypingState, stopTyping]);

  return (
    !chatEnded ?
      (<div className={classes.MessageComposer}>
        <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}>
          {!chatEnded ? (
            <><Text
              value={newMessage}
              onChange={handleTypingStart}
              placeholder={t('composer.placeholder', 'Type your message here...')}
              onEnter={sendMessage}
              name='message'
              maxLength={250}
              disabled={!chatId ? true : false}
            />{limitReached && <p className={classes.error}>250-character limit reached</p> }</>) : null}
        </div>
        
        {!chatEnded && chatId && mode === 'agent' && fileuploadEnabled ? (
          <div></div>
          /*<button className={classes.AttachFile} onClick={showUploadModal}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>attach_file</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>*/
        ) : null}
        {!chatEnded && chatId ? (
          <button className={classes.Send} onClick={sendMessage}>  
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.71285 12.6211L13.4765 3.53953C13.6229 3.39982 13.33 3.12038 13.1835 3.2601L3.95505 11.0842L0.43945 9.68707C-0.146483 9.47749 -0.146483 8.70905 0.43945 8.35976L15.3075 0.116474C15.9667 -0.232819 16.6259 0.256189 16.4794 0.884916L14.2089 14.158C14.0624 14.647 13.5497 14.9264 13.1103 14.7169L9.15521 13.1101L7.0312 15.4853C6.59175 16.0442 5.71285 15.7647 5.71285 15.0662V12.6211Z" fill="#0055ff"/>
          </svg>
          </button>
        ) : null}
      </div>
      ) : <></>
  );
};

export default MessageComposer;