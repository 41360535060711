import React from 'react';
import classes from './FeedbackModal.module.scss';
import Modal from '../Modal/Modal';
import { generateSurveyLink } from '../../api/Survey/Survey';
import { useSelector } from 'react-redux';

const FeedbackModal = ({isOpen, closeHandler}) => {
    const { chatId } = useSelector(state => state.genesys);
    const { agentName } = useSelector(state => state.general);
    
    const sendFeedback = () => {
        const newTab = window.open(generateSurveyLink(chatId, agentName), '_blank');
        closeHandler();
        newTab.focus();
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'}>
            <div className={classes.FeedbackModalContainer}>
                <h2>We value your feedback</h2>
                <div className={classes.FeedbackModalContent}>
                    <div className={classes.Label}>Thank you for contacting us, we hope we answered your questions today.</div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={sendFeedback}>Send feedback</button>
                        <button className={classes.ButtonCancel} onClick={closeHandler}>Skip</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FeedbackModal;