import React from 'react';
import PropTypes from 'prop-types';
import classes from './Input.module.scss';

const Text = (props) => {
  const {showError, errorMessage} = props;
  return (
    <div className={`${classes.Input} ${showError ? classes.error : ''}`}>
      {props.type === 'textarea' ? (
        <textarea
          value={props.value}
          onChange={(e) => props.onChange(props.name, e.target.value)}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          name={props.name}
          rows={props.rows}
          disabled={props.disabled}
        />
      ) : (
      <input 
        type={props.type}
        value={props.value}
        onChange={(e) => props.onChange(props.name, e.target.value)}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        name={props.name}
        disabled={props.disabled}
      />
      )}
      {showError ? (
        <div className={classes.ErrorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

Text.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.number,
};

export default Text;