import React from 'react';
import PropTypes from 'prop-types';
import classes from './Avatar.module.scss';
import { useSelector } from 'react-redux';

const Avatar = (props) => {
  const { agentName } = useSelector(state => state.general);

  if (!props.isAgent) {
    const style = {
      backgroundImage: `url(${props.pictureUrl})`,
      height: `${props.height}px`,
      width: `${props.width}px`,
      ...(props.borderRadius && {borderRadius: props.borderRadius}),
      ...(props.backgroundSize && {backgroundSize: props.backgroundSize}),
      ...(props.backgroundRepeat && {backgroundRepeat: props.backgroundRepeat}),
      ...(props.backgroundColor && {backgroundColor: props.backgroundColor}),
      ...(props.backgroundPositionX && {backgroundPositionX: `${props.backgroundPositionX}px`})
    };

    return <div className={classes.Avatar} style={style}></div>;
  } else {
    const avatarInitials = !agentName ? [''] : agentName.split(" ").map(word => word.charAt(0));
    return <div className={classes.AvatarAgent}>
      {avatarInitials.join('')}
    </div>;
  }
};

Avatar.propTypes = {
  isAgent: PropTypes.bool,
  pictureUrl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Avatar;
