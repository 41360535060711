import { 
  PRECHAT_RESET_STATE,
  SAVE_PRECHAT, 
  RESET_PRECHAT, 
  UPDATE_CATEGORY_FORM_DATA, 
  UPDATE_CATEGORY_STATE
} from '../constants/prechat';

export const prechatReset = () => {
  return (dispatch) => {
    dispatch({
      type: PRECHAT_RESET_STATE
    });
  };
};

export const savePrechat = (prechatData) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PRECHAT,
      firstName: prechatData.firstName,
      lastName: prechatData.lastName,
      email: prechatData.email,
      reason: prechatData.reason,
    });
  };
};

export const resetPrechat = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PRECHAT
    });
  };
};

export const updateCategoryFormData = (categoryFormData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CATEGORY_FORM_DATA,
      categoryFormData: categoryFormData
    });
  };
};

export const updateCategoryState = (categoryStateData) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CATEGORY_STATE,
      categoryStateData: categoryStateData
    });
  };
};