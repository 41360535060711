export const connect = (prechatFormData, selectedCategory) => {
    const userAgent = navigator.userAgent;
    const browserRegex = /(Firefox)|(Opera|OPR)|(Trident|MSIE)|(Edge)|(Chrome)|(Safari)/;
    const matches = userAgent.match(browserRegex);
    const browserName = matches && matches.length > 0 ? matches[0] : "Unknown";
    const genesysConfig = window.sutherland_variables.genesys;

    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('nickname', genesysConfig.nickname);
        formData.append('firstName', genesysConfig.firstName);
        formData.append('lastName', genesysConfig.lastName);
        formData.append('emailAddress', genesysConfig.emailAddress);
        formData.append('subject', genesysConfig.subject);
        formData.append('userData[GCTI_LanguageCode]', genesysConfig.languageCode);
        formData.append('userData[brand]', genesysConfig.brand);
        formData.append('userData[client]', 'United States');
        formData.append('userData[language]', genesysConfig.language);
        formData.append('userData[country]', 'United States');
        formData.append('userData[_genesys_source]', 'web');
        formData.append('userData[_genesys_referrer]', '');
        formData.append('userData[_genesys_url]', window.location.toString());
        formData.append('userData[_genesys_pageTitle]', genesysConfig.pageTitle);
        formData.append('userData[_genesys_browser]', browserName);
        formData.append('userData[_genesys_OS]', navigator.platform);
        formData.append('userData[_genesys_widgets]', genesysConfig.widgetsVersion);


        fetch(genesysConfig.apiUrl,
        {
            body: formData,
            method: "post"
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const refresh = (genesysData) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        if (!genesysData.chatEnded) {
            fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/refresh`,
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    alias: genesysData.alias,
                    secureKey: genesysData.secureKey,
                    userId: genesysData.userId, 
                    transcriptPosition: genesysData.nextPosition,
                    message: ''
                })
            })
                .then((response) => response.json())
                .then((data) => {
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                });
        } else {
            reject({})
        }
    });
}

export const typing = (genesysData) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        const eventName = genesysData.isStartTyping ? 'startTyping' : 'stopTyping'
        fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/${eventName}`,
        {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                alias: genesysData.alias,
                secureKey: genesysData.secureKey,
                userId: genesysData.userId, 
                transcriptPosition: genesysData.nextPosition,
                message: genesysData.message
            })
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const fileuploadConfig = (genesysData) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/file/limits`,
        {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                alias: genesysData.alias,
                secureKey: genesysData.secureKey,
                userId: genesysData.userId
            })
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const downloadFile = (genesysData, fileId) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/file/${fileId}/download`,
        {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                alias: genesysData.alias,
                secureKey: genesysData.secureKey,
                userId: genesysData.userId,
                fileId: fileId
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to download file');
                }
                return response.blob();
            })
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const send = (genesysData) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        const sendData = {
            alias: genesysData.alias,
            secureKey: genesysData.secureKey,
            userId: genesysData.userId, 
            transcriptPosition: genesysData.nextPosition,
            message: genesysData.message,
            messageType: 'text'
        }

        if (genesysData.eventAttributes) {
            sendData["eventAttributes"] = JSON.stringify(genesysData.eventAttributes)
        }

        fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/send`,
        {
            method: "post",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(sendData)
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const disconnect = (genesysData) => {
    const genesysConfig = window.sutherland_variables.genesys;
    return new Promise((resolve, reject) => {
        if (genesysData.chatId && !genesysData.chatEnded) {
            fetch(`${genesysConfig.apiUrl}/${genesysData.chatId}/disconnect`,
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    alias: genesysData.alias,
                    secureKey: genesysData.secureKey,
                    userId: genesysData.userId
                })
            })
            .then((response) => response.json())
            .then((data) => {
                resolve(data)
            })
            .catch((error) => {
                reject(error)
            });
        } else {
            console.log('Disconnect not executed, the session has either ended or the previous session was cached.')
            resolve(true)
        }
    });
}