import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './QuickResponse.module.scss';

const QuickResponseButton = (props) => {
  const [hovered, setHovered] = useState(false);
  const activateResponse = () => {
    if(props.open === "window") {
      window.open(props.url, '_blank', 'width=' + window.screen.width + ',height=' + window.screen.height);
    } else if(props.open === "tab") {
      window.open(props.url, "_blank");
    } else {
      props.activateResponse(props.quickResponse);
    }
  }

  return (
    <button
      className={classes.QuickResponseButton}
      onClick={activateResponse}
      style={hovered ? window.sutherland_variables.ui.quick_replies.hover : window.sutherland_variables.ui.quick_replies.normal}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.quickResponse.text}
    </button>
  );
};

QuickResponseButton.propTypes = {
  activateResponse: PropTypes.func,
  quickResponse: PropTypes.object
};

export default QuickResponseButton;
