import React, { useState } from 'react';
import classes from './ChatLauncher.module.scss';
import Avatar from '../Avatar/Avatar';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setWidgetIsOpen, 
  setPrechatEnabled, 
  setPrechatFormOpened, 
  setAvatarUrl 
} from '../../store/actions/general';

const ChatLauncher = () => {
  const dispatch = useDispatch();
  const { widgetIsOpen, prechatFormOpened, muiFontLoaded } = useSelector(state => state.general);
  const [ helpTextOpen, setHelpTextOpen ] = useState(true);
  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  const returnAvatar = () => {

    if (window.sutherland_variables.ui.launcher_icon === 'avatar') {
      return window.sutherland_variables.ui.avatar_url
    } else if (isUrl(window.sutherland_variables.ui.launcher_icon)) {
      return window.sutherland_variables.ui.launcher_icon
    }

    return false
  }
  const avatar = returnAvatar();
  const chatLauncherClasses = avatar === false ? [classes.ChatLauncher] : [classes.ChatLauncherAvatar];

  if (widgetIsOpen) {
    chatLauncherClasses.push('invisible');
  }

  const openWidget = () => {
    const { components, ui } = window.sutherland_variables;
    dispatch(setPrechatEnabled(components["prechat"]["enabled"]));
    dispatch(setPrechatFormOpened(prechatFormOpened));
    dispatch(setAvatarUrl(ui.avatar_url));
    dispatch(setWidgetIsOpen(true));
  };

  if (avatar !== false) {
    chatLauncherClasses.push('avatar');
  }
  
  const launcherHelpText = (!window.sutherland_variables.ui.launcher_help_text) ? '' : window.sutherland_variables.ui.launcher_help_text;

  return (
    <div className={classes.ChatLauncherContainer}>
      {launcherHelpText !== '' && helpTextOpen ? (
        <div className={classes.ChatLauncherMessage}>
          <button className={classes.IconButton} onClick={()=> setHelpTextOpen(false)}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>close</div>}
              width={80}
              height={80}
              backgroundColor={'transparent'} />
          </button>
          <div>{window.sutherland_variables.ui.launcher_help_text}</div>
        </div>
      ) : (<div/>)}
      <div
        className={chatLauncherClasses.join(' ')}
        onClick={openWidget}
        style={{
          width: window.sutherland_variables.ui.launcher_size,
          height: window.sutherland_variables.ui.launcher_size
        }}
      >
        {avatar !== false ? (
          <Avatar
            pictureUrl={avatar}
            height={window.sutherland_variables.ui.launcher_size}
            width={window.sutherland_variables.ui.launcher_size}
            borderRadius = "100%"
            backgroundSize = "50px 50px"
            backgroundRepeat = "no-repeat"
            backgroundColor = "white"
            backgroundPositionX = "12"
          />
        ) : (
          <div 
            className={classes.Icon}
            style={{
              fontSize: window.sutherland_variables.ui.launcher_size * 0.7
            }}
            >
              {window.sutherland_variables.ui.launcher_icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatLauncher;
