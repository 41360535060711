import React from 'react';
import { createRoot } from 'react-dom/client';
import * as retargetEvents from 'react-shadow-dom-retarget-events';
import './index.scss';
import App from './App';

const styleNodes = [];
let shadowRoot = null;
const headNode = document.head;
const config = { attributes: true, childList: true, subtree: true };
const observerCallback = function(mutationList, observer) {
  for(const mutation of mutationList) {
    if (mutation.type === 'childList') {
      for(const nodeElem of mutation.addedNodes) {
        if (nodeElem instanceof HTMLStyleElement) {
          shadowRoot.appendChild(nodeElem);
        }
      }
    }
  }
};

if (process.env.NODE_ENV !== 'production') {
  for(let elem of headNode.children) {
    if (elem instanceof HTMLStyleElement && elem.getAttribute('target') !== 'parent') {
      elem.setAttribute('loaded', true);
      styleNodes.push(elem);
    }
  }
}

const observer = new MutationObserver(observerCallback);

class SutherlandMessenger extends HTMLElement {
  static get observedAttributes() {
    return ['bot-id', 'enable-debug', 'engine-base-host'];
  }

  get botId() {
    return this.parseAttributes('bot-id', '', false);
  }

  get enableDebug() {
    return this.parseAttributes('enable-debug', false, true);
  }
  
  get engineBaseUrl() {
    const hostSubdomain = this.parseAttributes('engine-base-host', '', false);
    let baseUrl = hostSubdomain !== '' ? `https://${hostSubdomain}.sutherland.ai` : 'https://conversation-dev.sutherland.ai';

    if (hostSubdomain.includes('localhost') || hostSubdomain.includes('127.0.0.1')) {
      baseUrl = `http://${hostSubdomain}`;
    }

    return baseUrl;
  }

  parseAttributes(attrName, defaultValue, asObject) {
    try {
      const attributes = this.getAttribute(attrName);
      const parseAsObject = typeof asObject === 'undefined' ? true : asObject;
      if (attributes) {
        if (parseAsObject) {
          return JSON.parse(attributes);
        }
        return attributes;
      }
    } catch(e) {
      console.log(`Error loading ${attrName} attribute.`, e);
    }
    return defaultValue;
  }

  async fetchCssUrls(urls) {
    const results = [];
    
    for (const url of urls) {
      if (url) {
        const response = await fetch(url);
        const data = await response.text();
        results.push(data);
      }
    }
    
    return results;
  }

  mountChatMessenger(config) {
    if (config) {
      window.sutherland_variables = {
        botId: this.botId,
        enabled: config['enabled'],
        engineBaseUrl: this.engineBaseUrl,
        ui: config['ui'],
        components: config['components'],
        cssStylesUrl: config['css-styles-url'],
        i18n: config['i18n'],
        language: config['language'],
        genesys: config['genesys'],
        survey: config['survey'],
        sessionExpiry: config['sessionExpiry']
      }

      if (!config['enabled']) {
        return false;
      }

      const mountPoint = document.createElement('div');
      const cdnHost = process.env.NODE_ENV === 'production' ? 'cdn.sutherland.ai' : 'cdn-staging.sutherland.ai';
      const fontList = [
        'https://fonts.googleapis.com/icon?family=Material+Icons&elem=sutherland-messenger',
        'https://fonts.googleapis.com/css?family=Noto Sans:400,700',
        'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,400&display=swap',
        'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,300;1,400&display=swap',
        `https://${cdnHost}/static/fonts/proximanova/font.css`,
        'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'
      ];
      const externalCssUrls = (process.env.NODE_ENV === 'production' && window.sutherland_variables.cssStylesUrl !== '') ?
        [ window.sutherland_variables.cssStylesUrl ] : [];
      
      shadowRoot = this.attachShadow({ mode: 'open' });

      fontList.forEach((font) => {
        const fontHeaderElem = document.createElement('link');    
        fontHeaderElem.href = font;
        fontHeaderElem.rel = 'stylesheet';
        document.head.appendChild(fontHeaderElem);
      });

      this.fetchCssUrls(externalCssUrls)
        .then(cssContentArr => {
          for (const cssContent of cssContentArr) {
            const cssStyle = document.createElement('style');
            cssStyle.setAttribute('nonce', 'YmhhcmF0');
            cssStyle.innerHTML = cssContent;
            shadowRoot.appendChild(cssStyle);
          }

          const root = createRoot(mountPoint);
          styleNodes.forEach((node) => {
            shadowRoot.appendChild(node);
          });
          shadowRoot.appendChild(mountPoint);
          root.render(<App />);
          retargetEvents(shadowRoot);
        })
        .catch(error => {
          // handle the error
          console.log('error', error)
        });
    }
  }

  connectedCallback() {
    observer.observe(headNode, config);
    const urlParams = new URLSearchParams(window.location.search);
    let cacheDebug = JSON.parse(urlParams.get('enable_debug'));
    const sessionExpiry = JSON.parse(urlParams.get('session_expiry'));
    cacheDebug = !cacheDebug && !this.enableDebug ? false : true;
    const configKey = `${window.location.hostname}_${this.botId}_chatconfig`
    let chatConfig = !cacheDebug && !this.enableDebug ? sessionStorage.getItem(configKey) : false;
    let refreshConfig = false;

    if (!chatConfig) {
      refreshConfig = true;
    } else {
      const config = JSON.parse(chatConfig)
      const currentTime = new Date().getTime();
      if (currentTime - config['timeout'].timestamp >= config['timeout'].refreshAfter) {
        refreshConfig = true;
      }
    }

    if (refreshConfig) {
      const configHeaders = {
        'Content-Type': 'application/json',
        'bot-config': this.botId
      }

      if (cacheDebug) {
        configHeaders['bypass-cache'] = "true";
      }
      
      fetch(`${this.engineBaseUrl}/api/messenger/config`, {
        method: 'GET',
        headers: configHeaders
      })
        .then(response => response.json())
        .then(data => {
          data['config']['timeout'] = {
            timestamp: new Date().getTime(),
            refreshAfter: 15 * 60 * 1000 // 15 minutes
          }
          sessionStorage.setItem(configKey, JSON.stringify(data['config']))
          data['config']['sessionExpiry'] = sessionExpiry;
          this.mountChatMessenger(data['config'])
        });
    } else {
      chatConfig = JSON.parse(chatConfig);
      chatConfig['sessionExpiry'] = sessionExpiry;
      this.mountChatMessenger(chatConfig)
    }
  }
}

window.customElements.get("sutherland-messenger") ||
window.customElements.define("sutherland-messenger", SutherlandMessenger);
