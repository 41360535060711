import { 
  MESSAGES_RESET_STATE,
  SET_MESSAGES, 
  PATCH_MESSAGE,
  ADD_MESSAGE,
  SET_QUICK_RESPONSES,
  SET_MESSAGES_LOADED,
  SET_MODE,
  SET_AGENT_STATUS,
  SET_IS_TYPING,
  CANCEL_FILEUPLOADS,
  SHOW_UPLOAD
} from '../constants/messages';

export const messagesReset = () => {
  return (dispatch) => {
    dispatch({
      type: MESSAGES_RESET_STATE
    });
  };
};

export const setMessages = (messages) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGES,
      messages: messages
    });
  };
};

export const patchMessages = (payload) => {	
  return (dispatch) => {	
    dispatch({	
      type: PATCH_MESSAGE,	
      message: payload	
    });	
  };	
};

export const addMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      message: message
    });
  };  
};

export const setQuickResponses = (quickResponses) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_RESPONSES,
      quickResponses: quickResponses
    });
  };
};

export const setMessagesLoaded = (isLoaded) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGES_LOADED,
      messagesLoaded: isLoaded
    });
  };
};

export const setMode = (mode) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODE,
      mode: mode.toLowerCase()
    });
  };
};

export const setAgentStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_STATUS,
      agentStatus: status
    });
  };
};

export const setIsTyping = (isTyping) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_TYPING,
      typing: isTyping
    });
  };
};

export const cancelFileUploads = () => {	
  return (dispatch) => {	
    dispatch({	
      type: CANCEL_FILEUPLOADS	
    });	
  };	
};

export const setShowUpload = (show) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_UPLOAD,
      show
    });
  };
};