import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AUTHENTICATION_RESET_STATE, SET_USER_ID, SET_FINGERPRINT_ID } from '../constants/authentication';

export const authenticationReset = () => {
  return (dispatch) => {
    dispatch({
      type: AUTHENTICATION_RESET_STATE
    });
  };
};

export const setUserId = (customerId) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_ID,
      customerId
    });
  };
};

export const loadFingerprint = () => {
  return (dispatch) => {
    FingerprintJS.load()
      .then((fingerprint) => fingerprint.get())
      .then((fingerprint) => {
        dispatch({
          type: SET_FINGERPRINT_ID,
          fingerprintId: fingerprint.visitorId
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_FINGERPRINT_ID,
          fingerprintId: null
        });
      });
  };
};
