import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './PrechatForm.module.scss';
import Input from '../../components/controls/Input/Input';
import CheckBox from '../../components/controls/CheckBox/CheckBox';
import { setPrechatStatus } from '../../store/actions/general';
import { updateCategoryFormData, updateCategoryState, resetPrechat } from '../../store/actions/prechat';

const PrechatForm = () => {
    const dispatch = useDispatch();
    const { widgetIsOpen } = useSelector(state => state.general);
    const { showForm, formData, showError } = useSelector(state => state.prechat);

    const checkAvailability = (categoryKey, categoryLabel) => {
        dispatch(updateCategoryState({ field: 'showError', value: false }));
        dispatch(updateCategoryState({ field: 'selectedCategory', value: categoryKey }));
        dispatch(updateCategoryState({ field: 'selectedCategoryLabel', value: categoryLabel }));
        dispatch(updateCategoryState({ field: 'showForm', value: true }));
    }
  
    const handleInputChange = (field, value) => {
      dispatch(updateCategoryFormData({ field: field, value: value }));
    };

    const isValidEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    
    const handleContinueButtonClick = async () => {
      let isError = !formData.firstName || !formData.lastName || !isValidEmail(formData.email);
      dispatch(updateCategoryState({field: 'showError', value: isError }));
      if(!isError) {
          dispatch(setPrechatStatus(false));
          dispatch(updateCategoryState({ field: 'showForm', value: false }));
          dispatch(resetPrechat());
      }
    };
  
    const formCategories = window.sutherland_variables.i18n.en.prechat_categories;

    const categorySection = (
      <>
        <p>Thank you for contacting Crucial support. To make sure you get the right help, please select an option from the list below:</p>
        <ul className={classes.categoryLabel}>
        {formCategories.map(category => (
          <li key={category.key}>
            <button key={category.key} onClick={() => checkAvailability(category.key, category.label)}>
              {category.label}
            </button>
          </li>
        ))}
        </ul>
      </>
    )

    const randerInput = (type, fieldName, placeholder, isError = false, errorMessage = '', rows = 1) => {
      return (
        <div className='mb-4'>
        <Input
            type={type}
            value={formData[fieldName]}
            onChange={handleInputChange}
            placeholder={placeholder}
            name={fieldName}
            showError={isError 
              && ((fieldName==="email" && !isValidEmail(formData.email)) ? 
              true : formData[fieldName] === "" ? true : false)
            }
            errorMessage={errorMessage}
            rows={rows}
          />
        </div>
      )
    }

    const formSection = (
      <>
        <p className='mt-3'>Great. Before we begin, we need a little bit of info.</p>
        <form>
          {randerInput('text','firstName','First Name*', showError, 'First Name is required.')}
          {randerInput('text','lastName','Last Name*', showError, 'Last Name is required.')}
          {randerInput('Email Address','email','Email*', showError, 'Email Address is not a valid email address.')}
          {randerInput('textarea','describe','Please describe your issue*', showError, 'Enter a description for your issue.',6)}
          <div className='mb-4'>
            <CheckBox
              required = {false}
              checked = {formData['transcriptRequest']}
              onChange = {(e)=>handleInputChange('transcriptRequest',e)}
              label = "Transcript Request"
            />
          </div>
          <button type="button" onClick={handleContinueButtonClick}>Continue</button>
        </form>
      </>
    )

    const containerClass = widgetIsOpen ? classes.PrechatForm : classes.PrechatFormHidden;

    return (
      <div className={containerClass}>
          {!showForm && categorySection }
          {showForm &&  formSection }
      </div>
    );
};

export default PrechatForm;