import React from 'react';
import PropTypes from 'prop-types';
import classes from './CheckBox.module.scss';

const CheckBox = (props) => {
  const {showError, errorMessage} = props;


  return (
    <div className={showError ? classes.CheckBoxError : classes.CheckBox}>
      <label className={classes.checkBoxLabelRelate}>
        <input 
          type="checkbox"
          checked={props.checked}
          size="medium"
          required={props.required}
          onChange={(e) => {
            props.onChange(e.target.checked)
          }} 
        />
        &nbsp;<span className={classes.checkBoxLabel}>{props.label}</span>
      </label>
      {showError ? (
        <div className={classes.ErrorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default CheckBox;