import React from 'react';
import classes from './ErrorModal.module.scss';
import Modal from '../Modal/Modal';
import { disconnect } from '../../api/Connect/Connect';
import { setMessages, setQuickResponses } from '../../store/actions/messages';
import { setResetChat, setDrawerIsOpen } from '../../store/actions/general';
import { setApiError, resetGenesysSession, disconnectGenesysSession } from '../../store/actions/genesys';
import { useDispatch, useSelector } from 'react-redux';

const ErrorModal = ({isOpen, closeHandler}) => {
    const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);

    const dispatch = useDispatch();

    const restartChat = () => {
        dispatch(setApiError(false));
        dispatch(setDrawerIsOpen(false))
        dispatch(setMessages([]));
        dispatch(setQuickResponses([]));
        dispatch(setResetChat(false))
        dispatch(disconnectGenesysSession());
        disconnect({
            chatId, 
            alias, 
            secureKey,
            userId,
            chatEnded
        }).then(() => {
          setTimeout(() => {
            dispatch(resetGenesysSession());
          }, 1500)
        })
        .catch((error) => {
            console.log('Genesys Error', error)
            dispatch(setApiError(true));
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={() => {}} contentWidth={'80%'}>
            <div className={classes.ErrorModalContainer}>
                <h2>Connection error</h2>
                <div className={classes.ErrorModalContent}>
                    <div className={classes.Label}>Sorry, the bot is unable to connect to our servers. Please bear with us. You can choose to either restart the chat session or come back later.</div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={restartChat}>Restart Chat</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;