import { 
    GENESYS_RESET_STATE,
    INITIATE_GENESYS_SESSION,
    REFRESH_GENESYS_SESSION,
    DISCONNECT_GENESYS_SESSION,
    RESET_GENESYS_SESSION,
    SET_GENESYS_FILE_CONFIG,
    SET_API_ERROR
} from '../constants/genesys';
  
export const genesysReset = () => {
  return (dispatch) => {
    dispatch({
      type: GENESYS_RESET_STATE
    });
  };
};

export const initiateGenesysSession = (sessionData) => {
    return (dispatch) => {
      dispatch({
        type: INITIATE_GENESYS_SESSION,
        data: sessionData
      });
    };
};
  
export const refreshGenesysSession = (sessionData) => {
    return (dispatch) => {
      dispatch({
        type: REFRESH_GENESYS_SESSION,
        data: sessionData
      });
    }
};
  
export const disconnectGenesysSession = () => {
    return (dispatch) => {
      dispatch({
        type: DISCONNECT_GENESYS_SESSION
      });
    }
}

export const resetGenesysSession = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_GENESYS_SESSION
    });
  }
}

export const setFileConfig = (config) => {
  return (dispatch) => {
    dispatch({
      type: SET_GENESYS_FILE_CONFIG,
      config
    });
  }
}

export const setApiError = (hasError) => {
  return (dispatch) => {
    dispatch({
      type: SET_API_ERROR,
      hasError
    });
  }
}