import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import incomingClassesModule from './IncomingSystem.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function IncomingSystem(props) {
  const incomingClasses = [incomingClassesModule.IncomingSystem, messageBubbleClassesModule.MessageBubble];

  if (props.isTop) {
    incomingClasses.push(incomingClassesModule.top);
  }
  if (props.isBottom) {
    incomingClasses.push(incomingClassesModule.bottom);
  }

  return (
    <div style={{width: '100%'}}>
      <div className={incomingClasses.join(' ')}>
        <ReactMarkdown children={props.message.text} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );
}

IncomingSystem.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default IncomingSystem;