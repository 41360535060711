import React from 'react';
import PropTypes from 'prop-types';
import classes from './Tooltip.module.scss';

const Tooltip = ({ text, children }) => {
  return (
    <div className={classes.tooltip}>
      {children}
      <span className={classes.tooltiptext}>{text}</span>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default Tooltip;
