import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import incomingClassesModule from './IncomingAgent.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function IncomingAgent(props) {
  const incomingClasses = [incomingClassesModule.IncomingAgent, messageBubbleClassesModule.MessageBubble];
  const configAgent = window.sutherland_variables.ui.agent_messages;
  const bubbleStyles = configAgent ? {
    color: configAgent.text_color,
    backgroundColor: configAgent.bubble_color,
    fontSize: configAgent.font_size,
    fontFamily: configAgent.font_family,
    boxShadow: configAgent.shadow,
    lineHeight: configAgent.line_height,
    padding: configAgent.padding,
  } : {}

  if (props.isTop) {
    incomingClasses.push(incomingClassesModule.top);
  }
  if (props.isBottom) {
    incomingClasses.push(incomingClassesModule.bottom);
  }

  return (
    <div>
      <div className={incomingClasses.join(' ')} style={bubbleStyles}>
        <ReactMarkdown 
          children={props.message.text} 
          remarkPlugins={[remarkGfm]} 
          linkTarget="_blank"
        />
      </div>
      {props.isBottom ? (
        <div className={messageBubbleClassesModule.timeStamp}>
          {props.timeStamp}
        </div>
      ) : null}
    </div>
  );
}

IncomingAgent.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default IncomingAgent;
