import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setResetChat, setWidgetIsOpen, setDrawerIsOpen, setPrechatStatus } from '../../store/actions/general';
import { resetGenesysSession } from '../../store/actions/genesys';
import { setMessages, setQuickResponses, setMode } from '../../store/actions/messages';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import Tooltip from '../controls/Tooltip/Tooltip';
import { resetPrechat } from '../../store/actions/prechat';

const Header = ({socket}) => {
  const { showForm, isPrechatSubmitted } = useSelector(state => state.prechat);
  const { chatEnded } = useSelector(state => state.genesys);
  const { muiFontLoaded, prechatStatus } = useSelector(state => state.general);
  const dispatch = useDispatch();

  const minimizeWidget = () => {
    dispatch(setWidgetIsOpen(false));
    if (chatEnded) {
      dispatch(setWidgetIsOpen(false));
      dispatch(resetGenesysSession());
      dispatch(setMessages([]));
      dispatch(setMode('bot'));
      dispatch(setResetChat(false))
      dispatch(setQuickResponses([]));
      dispatch(setPrechatStatus(true));
      dispatch(resetPrechat());
    }
  };

  const closeWidget = () => {
    dispatch(setDrawerIsOpen(false));
    
    if (chatEnded || window.sutherland_variables.chat_ended) {
      dispatch(setWidgetIsOpen(false));
      dispatch(resetGenesysSession());
      dispatch(setMessages([]));
      dispatch(setMode('bot'));
      dispatch(setResetChat(false))
      dispatch(setQuickResponses([]));
      dispatch(setPrechatStatus(true));
      dispatch(resetPrechat());
    } else {
      dispatch(setResetChat(true))
    }
  };

  const backButtonHandler = () => {
    dispatch(resetPrechat());
  }

  return (
    <div className={classes.Header} style={window.sutherland_variables.ui.header_styles.background}>
      <div className={classes.HeaderTitle} style={window.sutherland_variables.ui.header_styles.title}>
        {prechatStatus && showForm && !isPrechatSubmitted ? 
          <div className={classes.BackIconButton} onClick={backButtonHandler}>
            <button>
                <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>arrow_back</div>}
                width={80}
                height={80}
                backgroundColor={'transparent'} />
            </button>
            Back
          </div>
          : 
          <>
          {window.sutherland_variables.ui.header_image_url ? (
            <div className={classes.Image}>
              <div>
              <img
                src={window.sutherland_variables.ui.header_image_url}
                alt='Logo'
                style={{
                  width: window.sutherland_variables.ui.header_image_size,
                }}
              />
              </div>
              <div>
                <Trans i18nKey="header.title">
                  {window.sutherland_variables.ui.header_title}
                </Trans>
                {window.sutherland_variables.ui.header_sub_title && 
                  <>
                    <br/>
                    <Trans i18nKey="header.subTitle">
                      <span className={classes.SubTitle} style={window.sutherland_variables.ui.header_styles.sub_title}>{window.sutherland_variables.ui.header_sub_title}</span>
                    </Trans>
                  </>
                 }
              </div>
            </div>
          ) : (
            <div>
              <Trans i18nKey="header.title">
                {window.sutherland_variables.ui.header_title}
              </Trans>
            </div>
          )} 
          </>
         }

        <div className={classes.HeaderButtons}>
          <DrawerMenu socket={socket}/>
          <Tooltip text="Minimize">
            <button className={classes.IconButton} onClick={minimizeWidget} style={{color: window.sutherland_variables.ui.header_styles.icon_colors}}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>remove</div>}
                width={30}
                height={30}
                backgroundColor={'#eeeeee'} />
            </button>
          </Tooltip>
          <Tooltip text="End chat">
            <button className={classes.IconButton} onClick={closeWidget} style={{color: window.sutherland_variables.ui.header_styles.icon_colors}}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>close</div>}
                width={30}
                height={30}
                backgroundColor={'#eeeeee'} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Header;