import React, { useState, useEffect, useRef, useCallback, Suspense } from 'react';
import classes from './MainContainer.module.scss';
import Header from '../../components/Header/Header';
//import PrechatForm from '../PrechatForm/PrechatForm';
import FileUploadModal from '../../components/FileUploadModal/FileUploadModal';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import EndChatModal from '../../components/EndChatModal/EndChatModal';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import MessageComposer from '../../components/MessageComposer/MessageComposer';
import MessageList from '../MessageList/MessageList';
import Typing from '../Typing/Typing';
import QuickResponseContainer from '../QuickResponseContainer/QuickResponseContainer';
import { connect, disconnect, refresh, fileuploadConfig } from '../../api/Connect/Connect';
import Message from '../../models/Message';
import QuickResponse from '../../models/QuickResponse';
import { addMessage, setQuickResponses, setIsTyping, setMode, setShowUpload, setMessagesLoaded } from '../../store/actions/messages';
import { setResetChat, setAgentName, setShowFeedback } from '../../store/actions/general';
import { initiateGenesysSession, refreshGenesysSession, disconnectGenesysSession, setFileConfig, setApiError } from '../../store/actions/genesys';
import { useDispatch, useSelector } from 'react-redux';

const MainContainer = () => {
  const components = window.sutherland_variables.components;
  const { prechatEnabled, widgetIsOpen, resetChat, showFeedback, prechatStatus } = useSelector(state => state.general);
  const { chatId, alias, secureKey, userId, nextPosition, chatEnded, fileConfig, hasApiError } = useSelector(state => state.genesys);
  const { messages, typing, mode, showUpload, messagesLoaded } = useSelector(state => state.messages);
  const [pingTime, setPingTime] = useState(null)
  const [refreshRunning, setRefreshRunning] = useState(false);
  const [fileUploadConfigRunning, setFileUploadConfigRunning] = useState(false);
  const messageList = useRef(null);
  const messageListBottom = useRef(null);
  // eslint-disable-next-line 
  const [fileuploadEnabled, setFileuploadEnabled] = useState(components["fileupload"]["enabled"])
  // eslint-disable-next-line 
  const [feedbackEnabled, setFeedbackEnabled] = useState(components["feedback"]["enabled"])
  const dispatch = useDispatch();
  
  const scrollToBottom = useCallback((shouldSmooth) => {
    if (shouldSmooth) {
      messageListBottom.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      messageList.current.scrollTop = messageList.current.scrollHeight;
    }
  }, []);

  const handleCloseUpload = () => {
    dispatch(setShowUpload(false));
  };

  const handleCloseFeedbackModal = () => {
    dispatch(setShowFeedback(false));
  };

  const handleCloseEndchatModal = () => {
    dispatch(setResetChat(false))
  }

  useEffect(() => {
    const handleChatEnd = () => {
      const {restart_chat} = window.sutherland_variables;
      if (!restart_chat) {
        // dispatch(setShowFeedback(true));
        dispatch(addMessage(new Message({
          incoming: true,
          isSystem: true,
          text: "Chat Ended",
          isSystemBottom: false
        })));
        dispatch(setMessagesLoaded(false))
      }
      window.sutherland_variables.restart_chat = false;
      dispatch(setQuickResponses([]));
    }

    const generateMessages = (messages, isInitial) => {
      // Participant ID: 
      // 1 - user
      // 2 - Bot
      // 3 - Agent
      // 4 - System
      for (const message of messages) {
        const messageFrom = !message.from ? {} : message.from;
        const fromType = messageFrom.type ? messageFrom.type.toLowerCase() : ''

        if (fromType === 'agent' && messageFrom.participantId >= 3 && message.type !== 'ParticipantJoined') {
          const nickname = !messageFrom.nickname ? 'Anonymous' : messageFrom.nickname;
          message.isAgent = true;
          dispatch(setAgentName(nickname))
          dispatch(setMode('agent'))
        }
        switch(message.type) {
          case "ParticipantJoined":
            if (['agent', 'client'].includes(fromType)) {
              message.incoming = true;
              message.isSystem = true;
              if (isInitial) {
                message.text = "Preparing Chat";
                message.isSystemBottom = false;
                dispatch(addMessage(new Message(message)));
              } else {
                if (messageFrom.participantId >= 3) {
                  message.text = `${messageFrom.nickname} connected`;
                } else {
                  message.text = 'Chat Started';
                }
                message.isSystemBottom = true;
                dispatch(addMessage(new Message(message)));
              }
            }
            break;
          case "Message":
            message.incoming = true;
            if ((message.messageType === 'text' || message.messageType === "Message" || message.isAgent) && fromType === 'agent') {
              const isEndChatTrigger = message.text.includes('[endchattrigger]: #');

              dispatch(addMessage(new Message(message)));
              if (!messagesLoaded) {
                const keyword_exclude = [" .", ".", "Greetings!"];
                if (!keyword_exclude.includes(message.text)) {
                  dispatch(setMessagesLoaded(true))
                }
              }
              
              if (isEndChatTrigger) {
                setTimeout(() => {
                  //handleChatEnd()
                  dispatch(setResetChat(false))
                  dispatch(disconnectGenesysSession());
                  disconnect({
                      chatId, 
                      alias, 
                      secureKey,
                      userId,
                      chatEnded
                  });
                }, 2000)
              } else {
                // Check Quick Replies
                const content = message?.eventAttributes?.['structured-content']?.['genesys-chat']?.['content'];
                let quickReplies = '';
                
                try {
                  quickReplies = JSON.parse(content);
                } catch {
                  quickReplies = '';
                }
                
                if (quickReplies !== '') {
                  if (Array.isArray(quickReplies.content) && quickReplies.contentType === 'quick-replies') {
                    const responsesList = [];
                    for (const option of quickReplies.content) {
                      option['eventAttributes'] = {
                        "general-properties": {
                          "related-event-id": message.index
                        }
                      }
                      const newQuickResponse = new QuickResponse(option);
                      responsesList.push(newQuickResponse);
                    }
                    dispatch(setQuickResponses(responsesList));
                  }
                }
              }
            } else if (message.type === "Message" && fromType === 'external') {
              message.isSystem = true;
              message.isSystemBottom = true;
              dispatch(addMessage(new Message(message)));
            }
            dispatch(setIsTyping(false))
            break;
          case "TypingStarted":
            if (fromType === 'agent') {
              dispatch(setIsTyping(true))
            }
            break;
          case "TypingStopped":
            if (fromType === 'agent') {
              dispatch(setIsTyping(false))
            }
            break;
          case "FileUploaded": 
            message.incoming = false;
            message.isFileMessage = true;
            message.fileData = message["userData"];
            dispatch(addMessage(new Message(message)));
            break;
          case "ParticipantLeft":
            if (fromType === 'client') {
              const quitCode = message.eventAttributes['GCTI_SYSTEM']['quit-reason-code']
              const reasonCodes = ["4", "5"];
              if (reasonCodes.includes(quitCode)) {
                //handleChatEnd()
              }
            }
            break;
          default:
            break;
        }
      }
    }
    
    const getfileUploadConfig = () => {
      if (!fileConfig && !fileUploadConfigRunning && chatId) {
        setFileUploadConfigRunning(true);
        fileuploadConfig({
          chatId, 
          alias,
          secureKey, 
          userId
        })
          .then((data) => {
            const messageInfo = data.messages[0] ? data.messages[0] : {}
            const configData = messageInfo.hasOwnProperty('userData') ? data.messages[0]['userData'] : {};
            dispatch(setFileConfig(configData));
            setFileUploadConfigRunning(false);
          })
      }
    }

    dispatch(setApiError(false));
    // if(!prechatStatus) {
      if (!chatId) {
        setTimeout(() => {
          connect()
            .then((data) => {
              window.sutherland_variables.chat_ended = false;

              dispatch(initiateGenesysSession(data));
              setPingTime(new Date());
              generateMessages(data.messages, true);
              getfileUploadConfig();
              setRefreshRunning(false);

              window.sutherland_variables.restart_chat = false;
            })
            .catch((error) => {
              console.log('Genesys Error', error)
              dispatch(setApiError(true));
            })
        }, 1000)
      } else if (!refreshRunning && !chatEnded) {
        setRefreshRunning(true);
        setTimeout(() => {
          if (!window.sutherland_variables.restart_chat && !window.sutherland_variables.chat_ended) {
            refresh({
                chatId, 
                alias, 
                secureKey,
                userId,
                nextPosition,
                chatEnded
              })
                .then((data) => {
                  dispatch(refreshGenesysSession(data));
                  setPingTime(new Date());
                  generateMessages(data.messages, false);
                  setRefreshRunning(false);
                  getfileUploadConfig();
                  if (data.chatEnded) {
                    handleChatEnd();
                  }
                })
                .catch((error) => {
                  console.log('Genesys Error', error)
                  dispatch(setApiError(true));
                })
          } else if (window.sutherland_variables.chat_ended) {
            handleChatEnd();
          }
        }, 1000)
      }
    // }
  }, [dispatch, chatId, prechatStatus, alias, secureKey, userId, nextPosition, pingTime, messages, messagesLoaded, chatEnded, resetChat, refreshRunning, fileConfig, fileUploadConfigRunning, scrollToBottom]);

  return (
    <div className={prechatEnabled || widgetIsOpen ? classes.MainContainer : classes.MainContainerGrowIn}>
      <div className={classes.MainContainerOuter}>
      <Header/>
      {/* {prechatStatus && <PrechatForm isOpen={true} />}
      {!prechatStatus && */}
        <> 
          <FileUploadModal isOpen={showUpload && mode === 'agent' && fileuploadEnabled} closeHandler={handleCloseUpload} />
          <FeedbackModal isOpen={showFeedback && feedbackEnabled} closeHandler={handleCloseFeedbackModal} />
          <EndChatModal isOpen={resetChat} closeHandler={handleCloseEndchatModal} />
          <ErrorModal isOpen={hasApiError} closeHandler={() => {}} />
          <div
            className={classes.content}
            style={{ overflowY: 'auto' }}
            ref={messageList}
          >
            <MessageList scrollToBottom={scrollToBottom} />
            <div ref={messageListBottom}></div>
          </div>

          <QuickResponseContainer/>

          <div className={classes.typeComposerSection}>
            <Typing show={typing} mode="dot-loader" typingText="" />
            {messagesLoaded &&
              <div className={classes.footer}>
                <Suspense fallback={<></>}>
                  <MessageComposer/>
                </Suspense>
              </div>
            }
          </div>
        </>
      {/* } */}
      </div>
    </div>
  );
};

export default MainContainer;