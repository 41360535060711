import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import classes from './FileUploadModal.module.scss';
import Modal from '../Modal/Modal';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

const commonMimeTypes = {
    txt: 'text/plain',
    htm: 'text/html',
    css: 'text/css',
    js: 'text/javascript',
    json: 'application/json',
    xml: 'application/xml',
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    tif: 'image/tiff',
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

const FileUploadModal = ({isOpen, closeHandler}) => {
    const { muiFontLoaded } = useSelector(state => state.general);
    const { chatId, alias, secureKey, userId, chatEnded, fileConfig } = useSelector(state => state.genesys);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);
    const fileInputRef = useRef(null);
    const allowedFileTypes = fileConfig && fileConfig.hasOwnProperty('upload-file-types') ? fileConfig['upload-file-types'].split(':') : []
    const extensions = allowedFileTypes.map(ext => `.${ext}`).join(', ');
    const maxUploadSize = fileConfig && fileConfig.hasOwnProperty('upload-max-file-size') ? parseInt(fileConfig['upload-max-file-size']) : 0;
    const fileInMB = Math.round(maxUploadSize / (1024*1024))

    const findExtensionByMimeType = (mimeType) => {
        const entry = Object.entries(commonMimeTypes).find(([extension, type]) => type === mimeType);
        return entry ? entry[0] : null;
    }

    const isValidFileType = (file) => {
        const fileType = file ? file.type : ''
        const ext = findExtensionByMimeType(fileType);

        if (!ext || !allowedFileTypes.includes(ext)) {
            setUploadError(`The file you selected is not allowed. Only files with type ${extensions} are allowed. Please upload another file.`)
            return false;
        }
        setUploadError('');
        return true;
    }

    const validateFileSize = (file) => {
        const fileSize = file ? file.size : 0
        if (fileSize > maxUploadSize) {
            setUploadError(`The file you selected is too big. The maximum file size allowed is ${fileInMB}MB. Please upload another file.`)
            return false;
        }
        
        setUploadError('');
        return true;
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    };
    
    const handleDrop = (event) => {
        event.preventDefault();
        const uploadedFile = event.dataTransfer.files[0]

        if (isValidFileType(uploadedFile) && validateFileSize(uploadedFile)) {
            setSelectedFile(uploadedFile);
        }
    };
    
    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0]
        
        if (isValidFileType(uploadedFile) && validateFileSize(uploadedFile)) {
            setSelectedFile(uploadedFile);
        }
    };
  
    const handleUpload = () => {
        // If no file selected, open the file dialog manually
        if (!selectedFile) {
          fileInputRef.current.click();
          return;
        }
    
        // Handle file upload logic here
        if (selectedFile && chatId && !chatEnded) {
            const genesysConfig = window.sutherland_variables.genesys;
            
            setIsUploading(true)
            const formData = new FormData();

            formData.append('userId', userId);
            formData.append('secureKey', secureKey);
            formData.append('alias', alias);
            formData.append('file', selectedFile);

            // Use XMLHttpRequest to handle the file upload and progress
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setProgressPercent(progress);
                    if (progress >= 100) {
                        handleClose()
                    }                    
                }
            });

            xhr.open('POST', `${genesysConfig.apiUrl}/${chatId}/file`, true);
            xhr.send(formData);

            setSelectedFile(null);
        }
    };

    const handleClose = () => {
        setSelectedFile(null)
        setUploadError('')
        setIsUploading(false)
        setProgressPercent(0)
        closeHandler();
    }

    return (
        <Modal 
            isOpen={isOpen} 
            contentWidth={'80%'}
            onClose={handleClose}>
            <div className={classes.FileUploadModalContainer}>
                <h2>Submit a file</h2>
                <div className={classes.FileUploadModalContent}>
                    {!isUploading &&
                        <div className={classes.FileUploader}>
                            {uploadError !== '' && <div className={classes.FileError}>{uploadError}</div>}
                            <div className={classes.DragPoint}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={handleUpload}>
                                <MuiPlaceholder
                                    isMuiFontLoaded={muiFontLoaded}
                                    element={<div className={classes.FileUploadIcon}>file_upload</div>}
                                    width={30}
                                    height={30}
                                    backgroundColor={'#eeeeee'} />
                                <div className={classes.Label}>
                                    Click or drag and drop a<br/>file to upload here.
                                    <span className={classes.AllowedFiles}><strong>Allowed files:</strong> ({extensions})</span>
                                    <span className={classes.AllowedFiles}><strong>Maximum file size:</strong> {fileInMB}MB</span>
                                </div>
                            </div>
                            <input
                                type="file"
                                accept={extensions}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            {selectedFile && <div className={classes.FileName}>
                                <strong>Selected file:</strong> {selectedFile.name}
                                <div className={classes.RemoveFile} onClick={() => {setSelectedFile(null)}}>
                                    <MuiPlaceholder
                                        isMuiFontLoaded={muiFontLoaded}
                                        element={<div className={classes.DeleteFile}>delete</div>}
                                        width={30}
                                        height={30}
                                        backgroundColor={'#eeeeee'} />
                                    Remove
                                </div>
                            </div>}
                        </div>
                    }
                    {isUploading &&
                        <div className={classes.Uploading}>
                            <div className={classes.ProgressIndicator}><progress value={progressPercent} max="100"></progress></div>
                            <div className={classes.UploadLoader}></div>
                            <div className={classes.Label}>
                                <strong>Sending file ... please wait</strong>
                            </div>
                        </div>
                    }
                    {!isUploading &&
                        <div className={classes.Buttons}>
                            {selectedFile && <button className={classes.ButtonEnd} onClick={handleUpload}>Upload</button>}
                            <button className={classes.ButtonCancel} onClick={handleClose}>Cancel</button>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default FileUploadModal;