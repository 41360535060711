import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../models/Message';
import { addMessage, setQuickResponses, setIsTyping } from '../../store/actions/messages';
import classes from './QuickResponseContainer.module.scss';
import QuickResponseButton from './QuickResponseButton/QuickResponseButton';
import { send } from '../../api/Connect/Connect';
import { refreshGenesysSession } from '../../store/actions/genesys';

const QuickResponseContainer = () => {
  const { chatId, alias, secureKey, userId, nextPosition } = useSelector(state => state.genesys);
  const { quickResponses } = useSelector(state => state.messages);
  const dispatch = useDispatch();
  
  const renderQuickResponses = () => {
    if (quickResponses && quickResponses.length > 0) {
      return <div>{quickResponses.map((quickResponse, index) => (
        <QuickResponseButton
          key={index}
          quickResponse={quickResponse}
          activateResponse={() => {
            dispatch(addMessage(new Message({
              text: quickResponse.text,
              incoming: false
            })));
            dispatch(setQuickResponses([]));
            send({
              chatId,
              alias, 
              secureKey, 
              userId, 
              nextPosition,
              message: quickResponse.text,
              eventAttributes: quickResponse.eventAttributes
            })
              .then((data) => {
                dispatch(refreshGenesysSession(data));
              });
            dispatch(setIsTyping(true))
          }}
        />
      ))}
      </div>
    }
  };

  return <div className={classes.QuickResponse}>
    {renderQuickResponses()}
  </div>;
};

export default QuickResponseContainer;